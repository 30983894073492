import React, { Component } from "react"
import "./IndexSubscribe.css"
import TextStyle from "../style-compoment/TextStyle"
import { Link } from "gatsby"
import CallToAction from "../style-compoment/CallToAction"
import app from 'gatsby-plugin-firebase-v9.0'
import { getDatabase, ref, push } from "firebase/database";
import check from "../../images/general-icon/check.png"
import "../contact-compoment/ContactForm.css"


class IndexSubscribe extends Component {

    state = {
        mail: "",
        errorFrench: "",
        errorEnglish: "",
        errorSpanish: "",
        validationEmail: true,
        recordedData: false,
        newsletter: false,
        recordedDataClass: "contactForm-checkbox-container",
        newsletterClass: "contactForm-checkbox-container",
        recordedDataCheck: "contactForm-check",
        newsletterCheck: "contactForm-check"
    }

    handleChangeMail(event) {
        this.setState({
            mail: event.target.value});
    }

    handleRecordedData() {
            this.setState({
                recordedData: !this.state.recordedData,
            });
            if (this.state.recordedData === false) {
                this.setState({
                    recordedDataClass: "contactForm-checkbox-container contactForm-checkbox-true",
                    recordedDataCheck: "contactForm-check contactForm-check-true"
                });
            } else {
                this.setState({
                    recordedDataClass: "contactForm-checkbox-container",
                    recordedDataCheck: "contactForm-check"
                });
            }
    }



    handleSendNewsletter() {
        this.setState({
            newsletter: !this.state.newsletter
        });
        if (this.state.newsletter === false) {
            this.setState({
                newsletterClass: "contactForm-checkbox-container contactForm-checkbox-true",
                newsletterCheck: "contactForm-check contactForm-check-true"

            });
        } else {
            this.setState({
                newsletterClass: "contactForm-checkbox-container",
                newsletterCheck: "contactForm-check"

            });
        }
    }

    SendContactForm = () => {

        console.log("Je teste heuu") 

        const mail = this.state.mail
        const newsletter = this.state.newsletter
        const recordedData = this.state.recordedData

        console.log("mail ", mail) 
        console.log("newsletter", newsletter) 
        console.log("recordData", recordedData)

        this.setState({
            errorFrench: "",
            errorEnglish: "",
            errorSpanish: "",
            validationEmail: true
        })


            if (mail === "") {
                this.setState({
                    errorFrench: "Tous les champs doivent être remplis",
                    errorEnglish: "All fields must be completed",
                    errorSpanish: "Todos los campos deben ser completados",
                    validationEmail: false
                })
            } else {
                const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                if(!mail || regex.test(mail) === false){
                    this.setState({
                        errorFrench: "L'adresse mail n'est pas valide",
                        errorEnglish: "Email is not valid",
                        errorSpanish: "La dirección de correo electrónico es inválida",
                        validationEmail: false
                    });
                } else {
                    if (recordedData === true) {

                    console.log("j'envoie")

                    const db = getDatabase();
                    const reference = ref(db, 'betatesteur');
                    push(reference, {
                        mail: mail,
                        newsletter: newsletter
                    }).then(() => {
                        this.setState({
                            errorFrench: "Votre message a bien été envoyé",
                            errorEnglish: "Your message has been sent",
                            errorSpanish: "Tu messaje ha sido enviado",
                            mail: ""
                        });
                    }).catch(() => {
                        this.setState({
                            errorFrench: "Un problème est survenu, veuillez réssayer plus tard",
                            errorEnglish: "Something went wrong, please try again later",
                            errorSpanish: "Algo salió mal, inténtalo de nuevo más tarde",
                            validationEmail: false
                        });
                    })

                    } else {
                        this.setState({
                            errorFrench: "Vous n'autorisez pas l'enregistrement de vos données",
                            errorEnglish: "You do not authorize the recording of your data",
                            errorSpanish: "No autorizas el registro de tus datos",
                            validationEmail: false
                        });
                    }
                
                }
            }
    

    }


    render () {

        let color = "var(--navyColor)"
        if (this.state.validationEmail === false) {
            color = "red"
        }

        return (

            <div className="IndexSubscribe-container">
                <TextStyle
                    id="subscribe"
                    class="title big center IndexSubscribe-title animate"
                    french={["Devenez bêta testeur du ", <span style={{color: "var(--orange)"}}>Kodness</span>]}
                    english="What customers say about us" 
                    spanish="Lo que dicen los clientes sobre nosotros"
                ></TextStyle>
                <TextStyle
                    class="medium center IndexSubscribe-subtitle"
                    french="Rejoignez la liste d'attente des bêta testeurs du Kodness en vous inscrivant ci-dessous !" 
                    english="What customers say about us" 
                    spanish="Lo que dicen los clientes sobre nosotros"
                ></TextStyle>

                <input 
                    className="IndexSubscribe-input"
                    type="email"
                    placeholder="example@email.com"
                    value={this.state.mail} 
                    onChange={e => this.handleChangeMail(e)}
                ></input>

                <TextStyle
                    class="small"
                    french={this.state.errorFrench}
                    english={this.state.errorEnglish}
                    spanish={this.state.errorSpanish}
                    cssStyle={{color: color, marginTop: "-15px"}}
                ></TextStyle>

                <div className="IndexSubscribe-newsletter-container">
                    <div className="contactForm-newsletter-container">
                        <div onClick={() => this.handleRecordedData()} className={this.state.recordedDataClass}>
                            <img className={this.state.recordedDataCheck} src={check} alt="true"></img>
                        </div>
                        <TextStyle
                            class="small contactForm-newsletter-text white"
                            french={["J'autorise Kodness à enregistrer mes données ", <Link to="/legalNotice">(consulter nos CGU)</Link>]}
                            english={["I authorize Kodness to save my data ", <Link to="/legalNotice">(see our T&Cs)</Link>]}
                            spanish={["Autorizo a Kodness a guardar mis datos ", <Link to="/legalNotice">(ver nuestros T&C)</Link>]}
                            cssStyle={{marginLeft: "15px"}}
                        ></TextStyle>                    
                    </div>
                    <div className="contactForm-newsletter-container">
                        <div onClick={() => this.handleSendNewsletter()} className={this.state.newsletterClass}>
                            <img className={this.state.newsletterCheck} src={check} alt="true"></img>
                        </div>
                        <TextStyle
                            class="small contactForm-newsletter-text white"
                            french="J’accepte de recevoir la newsletter de Kodness"
                            english="I agree to receive the Kodness newsletter"
                            spanish="Acepto recibir la newsletter de Kodness"
                            cssStyle={{marginLeft: "15px"}}
                        ></TextStyle>
                    </div>
                </div>


                <div className="IndexSubscribe-send-btn" 
                onClick={this.SendContactForm}
                >
                    <CallToAction
                        french="Envoyer"
                        english="Send"
                        spanish="Enviar"
                        color="navy"
                    ></CallToAction>
           
                </div>

            </div>

        )

    }
}

export default IndexSubscribe